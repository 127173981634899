<template>
    <div class="auth-wrapper auth-v2">
        <div class="auth-inner">
            <v-row class="auth-row ma-0">
                <v-col lg="7"
                       class="d-none d-lg-block position-relative overflow-hidden pa-0">
                    <div class="auth-illustrator-wrapper login-bg">
                        <div class="logo-size">
                            <img class="logo"
                                 :src="require(`@/assets/images/logo-designs/Reverse/Digital/myboat-logo-reverse-rgb.svg`)">
                        </div>
                    </div>
                </v-col>

                <v-col lg="5"
                       class="auth-bg pa-10 pb-0">
                    <v-row style="justify-content: end;">
                        <app-bar-i18n></app-bar-i18n>
                    </v-row>
                    <v-row class="pt-16 mt-10">
                        <v-col cols="12"
                               sm="8"
                               md="6"
                               lg="12"
                               class="mx-auto">
                            <v-card flat>
                                <v-card-text>
                                    <p class="text-2xl text-center text--primary mb-2">
                                        <span class="font-weight-light"> {{ $t('Welcome to') }}</span> <span
                                              class="font-weight-bold">{{ $t('MyBoat') }}</span>
                                        <!-- 👋🏻 -->
                                    </p>
                                    <p class="mb-2 mt-6 text-center">
                                        <!-- <span class="mx-2">
                                            <img style="height: 16px;"
                                                 src="@/assets/images/lock.png">
                                        </span>
                                        <span>This is a secure website</span> -->
                                    </p>
                                    <p style="font-size: 13px;"
                                       class="text-center">
                                        {{ $t('Please enter your Email or Phone and password to log in') }}
                                    </p>
                                </v-card-text>
                                <!-- login form -->
                                <v-card-text>
                                    <v-form ref="loginForm"
                                            @submit.prevent="handleFormSubmit">
                                        <p class="font-weight-semibold text-black mb-2">
                                            {{ $t('Email or Phone') }}
                                        </p>
                                        <v-text-field v-model="email"
                                                      outlined
                                                      placeholder="Email or Phone"
                                                      prepend-inner-icon="mdi-account"
                                                      :error-messages="errorMessages.email"
                                                      :rules="[validators.required, validators.emailValidator]"
                                                      hide-details="auto"
                                                      class="mb-6"></v-text-field>

                                        <p class="font-weight-semibold text-black mb-2">
                                            {{ $t('Password') }}
                                        </p>
                                        <v-text-field v-model="password"
                                                      outlined
                                                      :type="isPasswordVisible ? 'text' : 'password'"
                                                      :error-messages="errorMessages.password"
                                                      placeholder="Password"
                                                      :append-icon="password ? (isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline) : ''"
                                                      prepend-inner-icon="mdi-key"
                                                      :rules="[validators.required]"
                                                      hide-details="auto"
                                                      class="mb-2"
                                                      @click:append="isPasswordVisible = !isPasswordVisible"></v-text-field>

                                        <div class="d-flex align-center justify-end flex-wrap">
                                            <!-- <v-checkbox hide-details
                                                        label="Remember Me"
                                                        class="mt-0">
                                            </v-checkbox> -->

                                            <router-link :to="{ name: 'auth-forgot-password' }"
                                                         class="text-black ms-3">
                                                {{ $t('Forgot Password?') }}
                                            </router-link>
                                        </div>

                                        <v-btn block
                                               color="primary"
                                               type="submit"
                                               class="mt-6 text-lower">
                                            {{ $t('Sign In') }}
                                        </v-btn>
                                    </v-form>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import axios from '@axios'
import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
import { useRouter } from '@core/utils'
import { emailValidator, required } from '@core/utils/validation'
import
{
    mdiEyeOffOutline, mdiEyeOutline, mdiFacebook, mdiGithub, mdiGoogle, mdiTwitter,
} from '@mdi/js'
import themeConfig from '@themeConfig'
import { getCurrentInstance, ref } from '@vue/composition-api'
import configUrl from '/config';
import Swal from 'sweetalert2'

export default {
    components: {
        AppBarI18n,
    },
    setup()
    {
        // Template Ref
        const loginForm = ref(null)

        const vm = getCurrentInstance().proxy
        const { router } = useRouter()

        const isPasswordVisible = ref(false)

        const email = ref('')
        const password = ref('')
        const errorMessages = ref([])

        const socialLink = [
            {
                icon: mdiFacebook,
                color: '#4267b2',
                colorInDark: '#4267b2',
            },
            {
                icon: mdiTwitter,
                color: '#1da1f2',
                colorInDark: '#1da1f2',
            },
            {
                icon: mdiGithub,
                color: '#272727',
                colorInDark: '#fff',
            },
            {
                icon: mdiGoogle,
                color: '#db4437',
                colorInDark: '#db4437',
            },
        ]

        const handleFormSubmit = () =>
        {
            const isFormValid = loginForm.value.validate()

            if (!isFormValid) return
            const config = {
                headers: {
                    // authorization: process.env.VUE_APP_API_TOKEN,
                },
            }

            store.set('accounts/loader', true)
            axios.post(configUrl.MYBOAT_API_URL + 'v2/authenticate',
                { username: email.value, password: password.value }, config).then(response =>
                {
                    console.log('Login', response)
                    if (response.data.statusCode == 200)
                    {
                        store.set('accounts/loader', false)
                    }
                    // console.log('response', response.data.data.access_authorization)
                    // localStorage.setItem('authorization', response.data.data.access_authorization)

                    // // localStorage.setItem('role', response.data.user.role)
                    // store.set('accounts/userData', response.data.data.user)
                    // localStorage.setItem('role_name', response.data.data.user.role)
                    // localStorage.setItem('userData', JSON.stringify(response.data.data.user))

                    // const userAbility = [
                    //     {
                    //         action: 'manage',
                    //         subject: 'all',
                    //     },
                    // ]
                    // vm.$ability.update(userAbility)
                    // localStorage.setItem('userAbility', JSON.stringify(userAbility))

                    // delete user.ability
                    // this.$emit('loading-updated', true);
                    // store.set('cardStore/allCards', this.allCards);
                    // router.push('/dashboard')
                    // window.location.reload()
                    localStorage.setItem('email', email.value)
                    localStorage.setItem('password', password.value)
                    router.push({ name: 'one-time-passcode', params: { type: 'login' } });
                })
                .catch(error =>
                {
                    const swal = new Swal({
                        title: error.response.statusText,
                        confirmButtonColor: '#00b2a9',
                        text: error.response.data.message,
                        icon: 'error',
                    })
                    store.set('accounts/loader', false)
                    // console.log('error :>> ', error.response)
                    errorMessages.value = error.response.data.error
                })
        }

        return {
            handleFormSubmit,

            isPasswordVisible,
            email,
            password,
            errorMessages,
            socialLink,
            icons: {
                mdiEyeOutline,
                mdiEyeOffOutline,
            },
            validators: {
                required,
                emailValidator,
            },

            // themeConfig
            appName: themeConfig.app.name,
            appLogo: themeConfig.app.logo,

            // Template Refs
            loginForm,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

::v-deep .v-text-field input {
    padding: 8px 8px 8px;
}

::v-deep .v-input .v-input__append-inner .v-input__icon .v-icon {
    transform-origin: 10px;
    color: #00B2A9 !important;
}

::v-deep .v-input__icon .v-icon {
    color: #00B2A9 !important;
}

.text-black {
    color: #000;
}
</style>
